const BREADCRUMBS = {
  // Returns table
  'returns': [
    {
      text: 'Таблица возвратов',
      disabled: true
    }
  ],
  'returns-new': [
    {
      text: 'Создание заявки',
      disabled: true
    }
  ],
  'returns-edit': [
    {
      text: 'Изменение бронирования',
      disabled: true
    }
  ],

  // Users
  'users': [
    {
      text: 'Пользователи',
      disabled: true
    }
  ],
  'users-new': [
    {
      text: 'Пользователи',
      disabled: false,
      exact: true,
      to: '/users'
    },
    {
      text: 'Создание пользователя',
      disabled: true
    }
  ],
  'users-edit': [
    {
      text: 'Пользователи',
      disabled: false,
      exact: true,
      to: '/users'
    },
    {
      text: 'Изменение пользователя',
      disabled: true
    }
  ],

  //Processes
  'process-log': [
    {
      text: 'Журнал процессов',
      disabled: true
    }
  ],
  'processes': [
    {
      text: 'Список процессов',
      disabled: true
    }
  ],
  'process-new': [
    {
      text: 'Список процессов',
      disabled: false,
      exact: true,
      to: '/processes'
    },
    {
      text: 'Создать новый процесс',
      disabled: true
    }
  ],
  'process-edit': [
    {
      text: 'Список процессов',
      disabled: false,
      exact: true,
      to: '/processes'
    },
    {
      text: 'Изменить процесс',
      disabled: true
    }
  ],
  'starting-processes': [
    {
      text: 'Запуск процессов',
      disabled: true
    }
  ],

  // Suppliers
  'suppliers': [
    {
      text: 'Справочник поставщиков',
      disabled: true
    }
  ],
  'suppliers-new': [
    {
      text: 'Справочник поставщиков',
      disabled: false,
      exact: true,
      to: '/suppliers'
    },
    {
      text: 'Новый поставщик',
      disabled: true
    }
  ],
  'suppliers-edit': [
    {
      text: 'Справочник поставщиков',
      disabled: false,
      exact: true,
      to: '/suppliers'
    },
    {
      text: 'Изменить поставщика',
      disabled: true
    }
  ],

  // Booking changes
  'booking-changes': [
    {
      text: 'Журнал изменений бронирований',
      disabled: true
    }
  ],

  // Suppliers balance
  'suppliers-balance': [
    {
      text: 'Балансы поставщиков',
      disabled: true
    }
  ],

  // Regions
  'regions': [
    {
      text: 'Справочник регионов',
      disabled: true
    }
  ],
  'regions-new': [
    {
      text: 'Справочник регионов',
      disabled: false,
      exact: true,
      to: '/regions'
    },
    {
      text: 'Новый регион',
      disabled: true
    }
  ],
  'regions-edit': [
    {
      text: 'Справочник регионов',
      disabled: false,
      exact: true,
      to: '/regions'
    },
    {
      text: 'Изменить регион',
      disabled: true
    }
  ],

  // Return points
  'return-points': [
    {
      text: 'Справочник точек возвратов',
      disabled: true
    }
  ],
  'return-points-new': [
    {
      text: 'Справочник точек возвратов',
      disabled: false,
      exact: true,
      to: '/return-points'
    },
    {
      text: 'Новая точка возвратов',
      disabled: true
    }
  ],
  'return-points-edit': [
    {
      text: 'Справочник точек возвратов',
      disabled: false,
      exact: true,
      to: '/return-points'
    },
    {
      text: 'Изменить точку возвратов',
      disabled: true
    }
  ],

  // Trading networks
  'trading-networks': [
    {
      text: 'Справочник торговых сетей',
      disabled: true
    }
  ],
  'trading-networks-new': [
    {
      text: 'Справочник торговых сетей',
      disabled: false,
      exact: true,
      to: '/trading-networks'
    },
    {
      text: 'Новая торговая сеть',
      disabled: true
    }
  ],
  'trading-networks-edit': [
    {
      text: 'Справочник торговых сетей',
      disabled: false,
      exact: true,
      to: '/trading-networks'
    },
    {
      text: 'Изменить торговую сеть',
      disabled: true
    }
  ],

  // Legal entities
  'legal-entities': [
    {
      text: 'Справочник юридических лиц',
      disabled: true
    }
  ],
  'legal-entities-new': [
    {
      text: 'Справочник юридических лиц',
      disabled: false,
      exact: true,
      to: '/legal-entities'
    },
    {
      text: 'Новое юридическое лицо',
      disabled: true
    }
  ],
  'legal-entities-edit': [
    {
      text: 'Справочник юридических лиц',
      disabled: false,
      exact: true,
      to: '/legal-entities'
    },
    {
      text: 'Изменить юридическое лицо',
      disabled: true
    }
  ],

  // Warehouses
  'warehouses': [
    {
      text: 'Справочник складов RCC',
      disabled: true
    }
  ],
  'warehouses-new': [
    {
      text: 'Справочник складов RCC',
      disabled: false,
      exact: true,
      to: '/warehouses'
    },
    {
      text: 'Новый склад RCC',
      disabled: true
    }
  ],
  'warehouses-edit': [
    {
      text: 'Справочник складов RCC',
      disabled: false,
      exact: true,
      to: '/warehouses'
    },
    {
      text: 'Изменить склад RCC',
      disabled: true
    }
  ],

  // Return statuses
  'return-statuses': [
    {
      text: 'Справочник статусов возвратов',
      disabled: true
    }
  ],
  'return-statuses-new': [
    {
      text: 'Справочник статусов возвратов',
      disabled: false,
      exact: true,
      to: '/return-statuses'
    },
    {
      text: 'Новый статус возврата',
      disabled: true
    }
  ],
  'return-statuses-edit': [
    {
      text: 'Справочник статусов возвратов',
      disabled: false,
      exact: true,
      to: '/return-statuses'
    },
    {
      text: 'Изменить статус возврата',
      disabled: true
    }
  ],

  // Detailing
  'detailing': [
    {
      text: 'Справочник детализаций статусов',
      disabled: true
    }
  ],
  'detailing-new': [
    {
      text: 'Справочник детализаций статусов',
      disabled: false,
      exact: true,
      to: '/detailing'
    },
    {
      text: 'Новая детализация статусов',
      disabled: true
    }
  ],
  'detailing-edit': [
    {
      text: 'Справочник детализаций статусов',
      disabled: false,
      exact: true,
      to: '/detailing'
    },
    {
      text: 'Изменить детализацию статусов',
      disabled: true
    }
  ],

  // Analytics
  'analytics': [
    {
      text: 'Аналитика',
      disabled: true
    }
  ],

  // Notifications
  'notifications': [
    {
      text: 'Настройки оповещений',
      disabled: true
    }
  ],

  // User profile
  'user-profile': [
    {
      text: 'Мой профиль',
      disabled: true
    }
  ]
}

export default function(pageName) {
  return BREADCRUMBS[pageName] || []
}